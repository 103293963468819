import { render, staticRenderFns } from "./addClues.vue?vue&type=template&id=e77b020c&scoped=true&"
import script from "./addClues.vue?vue&type=script&lang=js&"
export * from "./addClues.vue?vue&type=script&lang=js&"
import style0 from "./addClues.vue?vue&type=style&index=0&id=e77b020c&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e77b020c",
  null
  
)

export default component.exports